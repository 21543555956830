<style lang="scss" scoped>
p {
    /*line-height: 24px;*/
    span {
        color: #909399;
        display: inline-block;

        &:first-child {
            width: 85px;
        }
    }
}

.list-content-first {
    width: 210px;
    border-right: 1px solid #F0F2F5;
    padding: 0 10px;
    font-size: 12px;

    p {
        line-height: 1em;
        display: flex;
        margin-bottom: 10px;
    }

    .first-top {
        /*height: 100px;*/
    }

    .first-bottom {
        /*height: 72px;*/
        margin-top: 20px;
    }
}

.item-bar {
    display: flex;
}

.item-bar-label {
    min-width: 85px;
    color: #909399;
}
</style>
<template>
    <div class="list-content-first">
        <div class="first-top">
            <p>
                <span>订单状态：</span>
                <span style="font-weight: 600;color:#333">{{ orderInfo.orderStatusName }}</span>
            </p>
            <p>
                <span>订单来源：</span>
                <span style="font-weight: 600;color:#333">
                    {{
                        orderInfo.sourceType | formatDesc(sourceTypeList,'value','name')
                    }}
                </span>
                <span v-if="orderInfo.fliggyTag" style="color: #FF3B30;font-weight: bold">（安心行）</span>
            </p>
            <p>
                <span>订单编号：</span>
                <span style="font-weight: 600;color:#333">{{ orderInfo.orderNo }}</span>
            </p>
            <p v-if="orderInfo.modifyStatus==1">
                <span>订单变更：</span>
                {{ userInfo.userType != 1 ? "待供应商确认变更" : "订单变更待处理" }}
            </p>
        </div>
        <div class="first-bottom">
            <div v-if="orderType !== 'insuranceList'">
                <div class="item-bar">
                    <div class="item-bar-label">下单机构：</div>
                    <div>
                        {{ orderInfo.merchantName || '-' }}
                    </div>
                </div>
                <p style="display: flex">
                    <span>下单人：</span>
                    {{
                        orderInfo.createOrderUserName ? orderInfo.createOrderUserName : '-'
                    }}&nbsp;{{ orderInfo.createOrderUserMobile }}
                </p>

                <p v-if="orderInfo.employeeNumber">
                    <span>员工号：</span>
                    {{ orderInfo.employeeNumber }}
                </p>
            </div>
            <p>
                <span>下单时间：</span>
                {{ orderInfo.createTime | dateCus('yyyy-MM-dd hh:mm:ss') }}
            </p>
            <p v-if="orderInfo.thirdOrderInfoDto">
                <span>源平台订单号：</span>
                <span v-if="orderInfo.ctripMasterOrderId">{{ orderInfo.ctripMasterOrderId }}</span>
                <span v-else>{{ orderInfo.thirdOrderInfoDto.orgPlatOrderId || '-' }}</span>
            </p>
            <div v-if="orderType !== 'insuranceList'">
                <p v-if="orderInfo.thirdOrderInfoDto">
                    <span>服务商订单号：</span>
                    {{ orderInfo.thirdOrderInfoDto.servProvOrderId }}
                </p>
                <p v-if="[12,15,18,19,23,24,25,28,31,30].includes(orderInfo.sourceType)">
                    <span>是否应单：</span>
                    {{ orderInfo.thirdOrderInfoDto.isRespond === 1 ? '是' : '否' }}
                </p>
                <p>
                    <span>发票状态：</span>
                    {{
                        orderInfo.invoiceStatus == 0 ? '未申请开票' : (orderInfo.invoiceStatus == 1 ? '待开票' : "已开票")
                    }}
                </p>
                <p>
                    <span>退款状态：</span>
                    {{ orderInfo.refundAmount > 0 ? '有退款' : '无退款' }}
                </p>
                <!--<p>点评状态：{{orderInfo.invoiceStatus == 0 ? '未评价' : '已评价'}}</p>-->
                <p>
                    <span>点评状态：</span>
                    未评价
                </p>
                <p v-if="orderInfo.tripRebateDto">
                    <span>分销商名称：</span>{{
                        orderInfo.tripRebateDto.merchantPidName ? orderInfo.tripRebateDto.merchantPidName + '-' : ''
                    }}{{ orderInfo.tripRebateDto.createMerchantName }}
                </p>
            </div>
        </div>
    </div>

</template>

<script type="text/ecmascript-6">
import dataInfo from "@/data/orderData/data";

export default {
    //定义模版数据
    data() {
        return {
            sourceTypeList: dataInfo.sourceTypeList,
        }
    },
    props: {
        orderInfo: {
            type: Object,
            default: function () {
                return {}
            }
        },
        userInfo: {
            type: Object,
            default: function () {
                return {};
            }
        },
        orderType: {
            type: String,
            default: null
        }
    }
}
</script>

