<template>
    <div>
        <el-main class="com-eTrip-section section-has-footer">
            <com-list-search>
                <el-form :inline="true" size="medium" slot="list-search-before">
                    <el-form-item>
                        <el-radio-group v-model="form.status">
                            <el-radio-button v-for="vm in status" :label="vm" :key="vm"></el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item>
                        <select-city ref="select-city" size="medium" :is-border="false"
                                     @update:city="form.cityId=$event.shortCode" clearable></select-city>
                    </el-form-item>
                    <el-form-item>
                        <supplier-select v-model="form.supplierId" clearable></supplier-select>
                    </el-form-item>
                    <el-form-item>
                        <el-input v-model="form.driverMobile" placeholder="司机手机号" clearable :maxlength="11"/>
                    </el-form-item>
                </el-form>
                <div slot="list-search-after">
                    <el-button @click="resetClick()">重置</el-button>
                    <el-button type="primary" @click="getList()" :loading="loading">搜索</el-button>
                    <el-button type="primary" plain @click="handleExport">导出</el-button>
                </div>
            </com-list-search>
            <SectionContent style="background-color: #fff">
                <el-table style="width: 100%;" border v-loading="loading" :data="limitData">
                    <el-table-column type="index" width="80" label="序号" align="center"></el-table-column>
                    <el-table-column label="司机名称" align="center" prop="driverName"></el-table-column>
                    <el-table-column label="城市" align="center" prop="cityName"></el-table-column>
                    <el-table-column label="供应商" align="center" prop="supplierName"></el-table-column>
                    <el-table-column label="司机手机号" align="center" prop="driverMobile"></el-table-column>
                    <el-table-column label="答题时间" align="center" prop="questionTime">
                        <template slot-scope="scope">
                            {{ scope.row.driverTrainingDate | dateCus }}
                        </template>
                    </el-table-column>
                    <el-table-column label="状态" align="center">
                        <template slot-scope="scope">
                            <el-tag :type="scope.row.driverTrainingDate?'success':'warning'">
                                {{ scope.row.driverTrainingDate ? '已完成' : '未完成' }}
                            </el-tag>
                        </template>
                    </el-table-column>
                </el-table>
            </SectionContent>
        </el-main>
        <el-footer style="display: flex;justify-content: flex-end;align-items: center">
            <el-pagination background layout="total,prev, pager, next"
                           :current-page.sync="form.pageIndex"
                           :page-size="form.pageSize"
                           :total="result.data.length">
            </el-pagination>
        </el-footer>
    </div>
</template>

<script>
import SectionContent from "@/components/sectionContent/index.vue";
import {exportDriverTrainingInfoByTrainingIdApi, getDriverTrainingInfoByTrainingIdApi} from "@/www/urls/driverLearning";
import comListSearch from "@/www/components/comListSearch/index.vue";
import SelectCity from "@/components/selectCity/index.vue";
import SupplierSelect from "@/www/components/supplier-select/index.vue";
import {combinationForm} from "@/common/js/common";

const createForm = () => ({
    pageIndex: 1,
    pageSize: 10,
    status: "全部",
    driverMobile: undefined,
    supplierId: undefined,
    cityId: undefined
})

export default {
    name: "completeDetails",
    data() {
        return {
            loading: false,
            status: ["全部", "已完成", "未完成"],
            form: createForm(),
            result: {
                data: [],
                total: 0
            }
        }
    },
    components: {
        SectionContent,
        comListSearch,
        SelectCity,
        SupplierSelect,
    },
    computed: {
        limitData() {
            return this.result.data.slice((this.form.pageIndex - 1) * this.form.pageSize, this.form.pageIndex * this.form.pageSize)
        }
    },
    activated() {
        this.init();
    },
    methods: {
        init() {
            this.getList();
            this.$store.commit('', this.$route.query.title)
        },
        getForm() {
            return {
                ...this.form,
                id: this.$route.query.id,
                isComplete: this.form.status === '已完成' ? 1 : (this.form.status === '未完成' ? 0 : undefined)
            }
        },
        async getList(page = 1) {
            this.form.pageIndex = page;
            this.loading = true
            const ret = await getDriverTrainingInfoByTrainingIdApi(combinationForm(this.getForm()))
            this.loading = false
            if (ret.success)
                this.result.data = ret.data.map((item, index) => ({...item, index: index + 1}));
            else
                this.$message.error(ret.errors[0].message)

        },
        resetClick() {
            this.$refs['select-city'].clear()
            this.form = createForm()
            this.form.pageIndex = 1;
            this.getList()
        },
        async handleExport() {
            this.loadingShow();
            this.loading = true;
            const ret = await exportDriverTrainingInfoByTrainingIdApi(this.getForm())
            this.loadingHide();
            this.loading = false;
            let d = new Date().toLocaleString();
            let a = document.createElement('a');
            a.download = (this.$route.query.name || '') + '完成情况' + d + '.xls';
            a.href = window.URL.createObjectURL(ret);
            a.click();
        }
    }
}
</script>

<style scoped lang="scss">


.com-eTrip-section {
    margin-top: 10px;

    &::v-deep {
        .list-search-after {
            width: 280px;
            text-align: right;
        }
    }

    .table {
        ul {
            display: flex;

            li {
                flex: 1;
            }
        }
    }
}

.message-info-btn {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
</style>
