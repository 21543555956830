<template>
    <div style="display:inline-block;">
        <span>{{ order.tripUserName }}, {{ order.tripUserMobile }}</span>
        <el-popover placement="bottom" width="300" title="修改出行人手机号" v-model="visible" size="mini"
                    v-if="orderInfo.sourceType===11&&[21,22,31,41].includes(orderInfo.orderStatus)">
            <el-form ref="form" label-position="left" :model="form" :rules="rules" size="mini">
                <el-form-item label="姓名" prop="tripUserName">
                    <el-input v-model="form.tripUserName" placeholder="输入姓名" disabled/>
                </el-form-item>
                <el-form-item label="手机号" prop="tripUserMobile">
                    <el-input v-model="form.tripUserMobile" placeholder="输入手机号"/>
                </el-form-item>
            </el-form>
            <div style="text-align: right; margin: 0">
                <el-button size="mini" type="text" @click="visible = false">取消</el-button>
                <el-button type="primary" size="mini" :disabled="form.tripUserMobile===order.tripUserMobile"
                           @click="handleSubmit" :loading="loading">确定
                </el-button>
            </div>
            <a slot="reference" class="color-second" href="javascript:void(0)" @click="handleShow"
               style="margin-left: 10px;">
                <i class="el-icon-edit-outline"></i>
                变更
            </a>
        </el-popover>
    </div>
</template>

<script>
import {updateOrderTripInfoApi} from '@/www/urls/orderUrls'
import {isCellphone} from "@/common/js/Utils";

export default {
    name: "edit-passenger-dialog",
    data() {
        return {
            loading: false,
            visible: false,
            order: {
                tripUserName: '',
                tripUserMobile: ''
            },
            form: {
                tripUserName: '',
                tripUserMobile: ''
            },
            rules: {
                tripUserName: [{required: true, message: '姓名'}],
                tripUserMobile: [{
                    required: true, validator: (rule, value, callback) => {
                        if (!value)
                            return callback('请输入手机号')
                        if (!isCellphone(value))
                            return callback('请输入正确手机号')
                        callback();
                    }
                }],
            }
        }
    },
    props: {
        orderInfo: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        userInfo() {
            return this.$store.state.loginUserInfo
        }
    },
    methods: {
        async handleSubmit() {
            try {
                await this.$refs['form'].validate();
                await this.$confirm('确认修改出行人手机号？', '提示')
                this.loading = true;
                const ret = await updateOrderTripInfoApi({
                    ...this.form,
                    id: this.orderInfo.orderId
                });
                if (ret.success) {
                    this.$message.success('变更成功!')
                    this.$emit('success', this.form.value)
                    this.visible = false;
                    this.order = {...this.form};
                } else this.$message.error(ret.errors[0].message)
                this.loading = false
            } catch (e) {
                this.visible = true;
                console.log(e);
            }
        },
        handleShow() {
            this.form.tripUserMobile = this.order.tripUserMobile
            this.form.tripUserName = this.order.tripUserName
        }
    },
    watch: {
        orderInfo: {
            handler(n) {
                this.order.tripUserMobile = n.tripUserMobile
                this.order.tripUserName = n.tripUserName
            },
            immediate: true
        }
    }
}
</script>

<style scoped>

</style>
