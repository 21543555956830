<script>
export default {
  name: "ProjectManage",
  data() {
    return {}
  }
}
</script>

<template>
  <div class="project-manage">
    <iframe style="width: 100%;"
            src="https://docs.qq.com/smartsheet/DUWhodXBiRWpKZEVo?viewId=vMzzjG&tab=ss_8einmn"></iframe>
  </div>
</template>

<style scoped lang="scss">
.project-manage {
  overflow: hidden;
  height: 100%;
  padding: 20px;
  background-color: #f8f8f8;

  iframe {
    margin-top: -60px;
    background-color: #fff;
    height: calc(100% + 60px);
  }
}
</style>
