export default [
    {
        path: '*',
        redirect: '/home'
    },
    {
        path: '/home',
        name: 'home',
        meta: {
            title: '首页',
            free: true
        },
        component: r => require.ensure([], () => r(require('../pages/home/')), 'group-home')
    },
    {
        path: '/mechanismList', // 机构管理--机构列表
        name: 'mechanismList',
        meta: {
            title: '机构列表',
            auth: true
        },
        component: r => require.ensure([], () => r(require('../pages/mechanism/mechanismList/index')), 'mechanism')
    },
    {
        path: '/newAddMechanism', // 机构管理--新增机构
        name: 'newAddMechanism',
        meta: {
            title: '新增机构',
            auth: true
        },
        component: r => require.ensure([], () => r(require('../pages/mechanism/newAddMechanism/index')), 'mechanism')
    },
    {
        path: '/editMechanism/:id', // 机构管理--新增机构
        name: 'editMechanism',
        meta: {
            title: '编辑机构'
        },
        component: r => require.ensure([], () => r(require('../pages/mechanism/newAddMechanism/index')), 'mechanism')
    },
    {
        path: '/LookMechanism/:id', // 机构管理--新增机构
        name: 'LookMechanism',
        meta: {
            title: '查看机构'
        },
        component: r => require.ensure([], () => r(require('../pages/mechanism/newAddMechanism/index')), 'mechanism')
    },
    {
        path: '/bEndUserList', // B端用户管理--B端用户列表
        name: 'bEndUserList',
        meta: {
            title: 'B端用户列表',
            auth: true
        },
        component: r => require.ensure([], () => r(require('../pages/bEndUser/bEndUserList/index')), 'bEndUser')
    },
    {
        path: '/supplierList', // 供应商管理--供应商列表
        name: 'supplierList',
        meta: {
            title: '供应商列表',
            auth: true
        },
        component: r => require.ensure([], () => r(require('../pages/supplierManage/supplierList/index')), 'supplierManage')
    },
    {
        path: '/operatorList', // 运营商管理--运营商列表
        name: 'operatorList',
        meta: {
            title: '运营商列表',
            auth: true
        },
        component: r => require.ensure([], () => r(require('../pages/operatorManage/operatorList/index')), 'operatorManage')
    },
    {
        path: '/auditList', // 审核管理--审核列表
        name: 'auditList',
        meta: {
            title: '审核列表',
            auth: true
        },
        component: r => require.ensure([], () => r(require('../pages/auditManage/auditList/index')), 'auditManage')

    },
    {
        path: '/auditDetail/:id/:type', // 审核管理--审核详情
        name: 'auditDetail',
        meta: {
            title: '审核详情'
        },
        component: r => require.ensure([], () => r(require('../pages/auditManage/auditDetail/index')), 'auditManage')
    },
    {
        path: '/userTypePer', // 软件权限管理--用户类型的权限分配
        name: 'userTypePer',
        meta: {
            title: '用户类型的权限分配',
            auth: true
        },
        component: r => require.ensure([], () => r(require('../pages/softwarePer/userTypePer/index')), 'softwarePer')
    },
    {
        path: '/perSetting', // 软件权限管理--权限设置
        name: 'perSetting',
        meta: {
            title: '权限设置',
            auth: true
        },
        component: r => require.ensure([], () => r(require('../pages/softwarePer/perSetting/index')), 'softwarePer')
    },
    {
        path: '/userTypeRole', // 软件权限管理--权限设置
        name: 'userTypeRole',
        meta: {
            title: '用户类型下的角色权限分配',
            auth: true
        },
        component: r => require.ensure([], () => r(require('../pages/softwarePer/userTypeRole/index')), 'softwarePer')
    },
    {
        path: '/addUserTypeRole', // 软件权限管理--权限设置
        name: 'addUserTypeRole',
        meta: {
            title: '添加用户下角色',
            auth: true
        },
        component: r => require.ensure([], () => r(require('../pages/softwarePer/addUserTypeRole/index')), 'softwarePer')
    },
    {
        path: '/addTimer', // 订单投诉 管理后台 定时器
        name: 'addTimer',
        component: r => require.ensure([], () => r(require('../pages/addTimer/index')), 'addTimer')
    },
    {
        path: '/loginAccountList', // 登陆账号
        name: 'loginAccountList',
        meta: {
            title: '登陆账号列表',
            auth: true
        },
        component: r => require.ensure([], () => r(require('../pages/loginAccountManage/loginAccountList/index')), 'loginAccountManage')
    },
    {
        path: '/productList', // 产品列表
        name: 'productList',
        meta: {
            title: '产品列表',
            auth: true
        },
        component: r => require.ensure([], () => r(require('../pages/productManage/productList/index')), 'productManage')
    },
    {
        path: '/addproduct', // 新增产品
        name: 'addproduct',
        meta: {
            title: '新增产品'
        },
        component: r => require.ensure([], () => r(require('../pages/productManage/index')), 'productManage'),
        children: [{
            path: 'basicInfo/:tabIndex', // 新增产品
            name: 'basicInfo',
            meta: {
                title: '新增产品'
            },
            component: r => require.ensure([], () => r(require('../pages/productManage/productAddOrEdit/index')), 'productManage')
        },
            {
                path: 'resourceManage/:productId/:productNo/:productOperatorId/:productType/:productForm', // 资源管理
                name: 'resourceManage',
                meta: {
                    title: '新增产品'
                },
                component: r => require.ensure([], () => r(require('../pages/productManage/resourceManage/index')), 'productManage')
            }, {
                path: 'priceInventory/:productId/:productNo/:resourceId/:productType/:productForm', // 资源管理
                name: 'priceInventory',
                meta: {
                    title: '新增产品'
                },
                component: r => require.ensure([], () => r(require('../pages/productManage/priceInventory/index')), 'productManage')
            }]
    },
    {
        path: '/editproduct', // 新增产品
        name: 'editproduct',
        meta: {
            title: '编辑产品'
        },
        component: r => require.ensure([], () => r(require('../pages/productManage/editProduct/index')), 'productManage'),
        children: [{
            path: 'basicInfo/:tabIndex/:productId', // 新增产品
            name: 'editbasicInfo',
            meta: {
                title: '编辑产品'
            },
            component: r => require.ensure([], () => r(require('../pages/productManage/productAddOrEdit/index')), 'productManage')
        }, {
            path: 'resourceManage/:productId/:productNo/:productOperatorId/:productType/:productForm', // 资源管理
            name: 'editresourceManage',
            meta: {
                title: '编辑产品'
            },
            component: r => require.ensure([], () => r(require('../pages/productManage/resourceManage/index')), 'productManage')
        }, {
            path: 'priceInventory/:productId/:productNo/:productOperatorId/:resourceId/:productType/:productForm', // 资源管理
            name: 'editpriceInventory',
            meta: {
                title: '编辑产品'
            },
            component: r => require.ensure([], () => r(require('../pages/productManage/priceInventory/index')), 'productManage')
        }]
    },
    {
        path: '/invoiceList', // 发票管理
        name: 'invoiceList',
        meta: {
            title: '发票列表',
            auth: true
        },
        component: r => require.ensure([], () => r(require('../pages/invoiceManage/invoiceList/index')), 'invoiceManage'),
    },
    {
        path: '/redInvoiceList', // 发票管理
        name: 'redInvoiceList',
        meta: {
            title: '冲红列表',
            auth: true
        },
        component: r => require.ensure([], () => r(require('../pages/invoiceManage/redInvoiceList/index')), 'invoiceManage'),
    },
    {
        path: '/driverList', // 司机列表
        name: 'driverList',
        meta: {
            title: '司机管理',
            auth: true
        },
        component: r => require.ensure([], () => r(require('../pages/driverManage/driverList/index')), 'driverManage'),
    },
    {
        path: '/antiEpidemicList', // 防疫登记
        name: 'antiEpidemicList',
        meta: {
            title: '防疫登记列表',
            auth: true
        },
        component: r => require.ensure([], () => r(require('../pages/driverManage/antiEpidemicList/index')), 'driverManage'),
    },
    {
        path: '/vehicleList', // 车辆列表
        name: 'vehicleList',
        meta: {
            title: '车辆管理',
            auth: true
        },
        component: r => require.ensure([], () => r(require('../pages/driverManage/vehicleList/index')), 'driverManage'),
    },
    {
        path: '/driverAndCarExport', // 导出记录
        name: 'driverAndCarExport',
        meta: {
            title: '导出记录',
            auth: true
        },
        component: r => require.ensure([], () => r(require('../pages/driverManage/exportList/index')), 'driverManage'),
    },
    {
        path: '/driverDetail/:id', // 司机列表
        name: 'driverDetail',
        meta: {
            title: '司机详情'
        },
        component: r => require.ensure([], () => r(require('../pages/driverManage/driverDetail/index')), 'driverManage'),
    },
    {
        path: '/driverBlacklist', // 司机列表
        name: 'DriverBlacklist',
        meta: {
            title: '司机车辆黑名单'
        },
        component: r => require.ensure([], () => r(require('../pages/driverManage/blacklist/index')), 'driverManage'),
    },
    {
        path: '/nonCompliant', // 司机列表
        name: 'NonCompliant',
        meta: {
            title: '不合规司机车辆'
        },
        component: r => require.ensure([], () => r(require('../pages/driverManage/nonCompliant/index')), 'driverManage'),
    },
    {
        path: '/vehicleDetail/:id', // 车辆详情
        name: 'vehicleDetail',
        meta: {
            title: '车辆详情'
        },
        component: r => require.ensure([], () => r(require('../pages/driverManage/vehicleDetail/index')), 'driverManage'),
    },
    {
        path: '/addVehicle', // 添加车辆
        name: 'addVehicle',
        meta: {
            title: '添加车辆',
            auth: true
        },
        component: r => require.ensure([], () => r(require('../pages/driverManage/addVehicle/index')), 'driverManage'),
    },
    {
        path: '/fleetList', // 车队列表
        name: 'fleetList',
        meta: {
            title: '车队列表',
            auth: true  // 是否需要权限
        },
        component: r => require.ensure([], () => r(require('../pages/driverManage/fleetList/index')), 'driverManage'),
    },
    {
        path: '/fleetDetail/:id/:editType', // 车队详情
        name: 'fleetDetail',
        meta: {
            title: '创建车队'
        },
        component: r => require.ensure([], () => r(require('../pages/driverManage/fleetDetail/index')), 'driverManage'),
    },
    {
        path: '/fleetEditDetail/:id/:editType', // 车队详情  1编辑
        name: 'fleetEditDetail',
        meta: {
            title: '编辑车队'
        },
        component: r => require.ensure([], () => r(require('../pages/driverManage/fleetDetail/index')), 'driverManage'),
    },
    {
        path: '/fleetSeeDetail/:id/:editType', // 车队详情 2查看
        name: 'fleetSeeDetail',
        meta: {
            title: '查看车队'
        },
        component: r => require.ensure([], () => r(require('../pages/driverManage/fleetDetail/index')), 'driverManage'),
    },
    {
        path: '/fleetBindVerify', // B端用户绑定车队
        name: 'fleetBindVerify',
        meta: {
            title: '合作机构',
            auth: true
        },
        component: r => require.ensure([], () => r(require('../pages/driverManage/fleetBindVerify/index')), 'driverManage'),
    },
    {
        path: '/previewImage/:url', // 司机列表
        name: 'previewImage',
        meta: {
            title: '图片预览'
        },
        component: r => require.ensure([], () => r(require('@/components/previewImage/')), 'previewImage'),
    },
    {
        path: '/rechargeAudit', // 财务管理
        name: 'rechargeAudit',
        meta: {
            title: '充值审核',
            auth: true
        },
        component: r => require.ensure([], () => r(require('../pages/financeManage/rechargeAudit/index')), 'financeManage'),
    },
    {
        path: '/storeBalance', // 财务管理
        name: 'storeBalance',
        meta: {
            title: '门市余额',
            auth: true
        },
        component: r => require.ensure([], () => r(require('../pages/financeManage/storeBalance/index')), 'financeManage'),
    },
    {
        path: '/transferAccounts', // 转账管理
        name: 'transferAccounts',
        meta: {
            title: '转账记录',
            auth: true
        },
        component: r => require.ensure([], () => r(require('../pages/financeManage/transferAccounts/index')), 'financeManage'),
    },
    {
        path: '/transferAccountsAdd', // 新增转账管理
        name: 'transferAccountsAdd',
        meta: {
            title: '转账申请',
            auth: false
        },
        component: r => require.ensure([], () => r(require('../pages/financeManage/transferAccounts/add.vue')), 'financeManage'),
    },
    {
        path: '/transferAccountsDetail', // 新增转账管理
        name: 'transferAccountsDetail',
        meta: {
            title: '转账详情',
            auth: false
        },
        component: r => require.ensure([], () => r(require('../pages/financeManage/transferAccounts/detail.vue')), 'financeManage'),
    },
    {
        path: '/transferAccountsFlow', // 新增转账管理
        name: 'transferAccountsFlow',
        meta: {
            title: '流水信息表',
            auth: false
        },
        component: r => require.ensure([], () => r(require('../pages/financeManage/transferAccounts/flow.vue')), 'financeManage'),
    },
    {
        path: '/transferAccountsPrint', // 新增转账管理
        name: 'transferAccountsPrint',
        meta: {
            title: '打印预览',
            auth: false
        },
        component: r => require.ensure([], () => r(require('../pages/financeManage/transferAccounts/print.vue')), 'financeManage'),
    },
    {
        path: '/transferAccountsPassword', // 安全密码
        name: 'transferAccountsPassword',
        meta: {
            title: '安全密码',
            auth: false
        },
        component: r => require.ensure([], () => r(require('../pages/financeManage/transferAccounts/password.vue')), 'financeManage'),
    },
    {
        path: '/rechargeDetail/:id', // 财务管理
        name: 'rechargeDetail',
        meta: {
            title: '门市充值详情'
        },
        component: r => require.ensure([], () => r(require('../pages/financeManage/rechargeDetail/index')), 'financeManage'),
    },
    //=========司机服务 充值服务========
    {
        path: '/rechargeList',
        name: 'rechargeList',
        meta: {
            title: '服务充值记录',
            auth: true
        },
        component: r => require.ensure([], () => r(require('../pages/driverService/rechargeList/index')), 'rechargeList'),
    },
    {
        path: '/preJobQuestion', // 司机岗前宣导
        name: 'preJobQuestion',
        meta: {
            title: '岗前宣导列表',
            auth: true
        },
        component: r => require.ensure([], () => r(require('../pages/driverManage/prejobAnswer/index')), 'driverManage'),
    },
    {
        path: '/preJobAnswer', // 司机岗前宣导，答题详情
        name: 'preJobAnswer',
        meta: {
            title: '岗前宣导详情',
        },
        component: r => require.ensure([], () => r(require('../pages/driverManage/prejobAnswer/detail')), 'driverManage'),
    },
    {
        path: '/modelManage',
        name: "modelManage",
        meta: {
            title: "车型管理"
        },
        component: r => require.ensure([], () => r(require('../pages/modelManage/index')))
    },
    {
        path: '/projectManage',
        name: 'ProjectManage',
        meta: {
            title: '技术部项目管理'
        },
        component: r => require.ensure([], () => r(require('../pages/projectManage/index')))
    }
]
