<style lang="scss" scoped>
/deep/ {
    .common-list-search .list-search-after {
        width: 518px !important;
    }

    .common-list-search .list-search-before {
        width: calc(100% - 420px) !important;
    }
}
</style>
<template>
    <div>
        <com-list-search>
            <div slot="list-search-before">
                <el-form :inline="true" :model="form" size="small">
                    <el-form-item v-if="userInfo.userType!=1" style="width: auto">
                        <el-radio-group v-model="form.status">
                            <el-radio-button v-for="(vm,index) in status"
                                             :key="index"
                                             :label="vm.value">
                                {{ vm.label }}
                            </el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item>
                        <el-select v-model="form.quoteChannel"
                                   filterable
                                   placeholder="渠道">
                            <el-option label="全部渠道" value=""></el-option>
                            <el-option v-for="item in channels"
                                       :value="item.value"
                                       :label="item.label"
                                       :key="item.value"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="">
                        <el-select v-model="form.quoteType"
                                   placeholder="报价类型">
                            <el-option label="全报价类型" value=""></el-option>
                            <el-option
                                v-for="item in quotationTypeList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="" v-if="form.quoteType!==2">
                        <el-select v-model="form.type"
                                   placeholder="服务类型">
                            <el-option label="全产品类型" value=""></el-option>
                            <el-option
                                v-for="item in quotationProductionTypes"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="">
                        <seat-model v-model="form.vehicleType" is-all></seat-model>
                    </el-form-item>
                    <el-form-item label="">
                        <select-city ref="selectCity" @update:city="selectCity" :is-border="false"></select-city>
                    </el-form-item>
                    <el-form-item>
                        <el-select v-model="form.stationCode" clearable filterable placeholder="请选择站点">
                            <el-option
                                v-for="s in getStationList"
                                :key="s.airportCode"
                                :label="s.station"
                                :value="s.airportCode">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item v-if="userInfo.userType==0">
                        <operator-info-input v-model="form.operatorId" @clear="handleClear" ref="operatorInfoInput"
                                             clearable>
                        </operator-info-input>
                    </el-form-item>
                    <el-form-item v-if="userInfo.userType!=1">
                        <supplier-info-input v-model="form.supplierId" :operatorId="form.operatorId"
                                             :userType="supplierUserType" ref="supplierInfoInput"
                                             clearable></supplier-info-input>
                    </el-form-item>
                    <el-form-item>
                        <el-input type="number" v-model="form.ruleNo" placeholder="报价编号" clearable></el-input>
                    </el-form-item>
                    <el-form-item v-if="form.quoteType==2">
                        <el-select placeholder="是否跨城" v-model="form.isAcross" clearable>
                            <!--                            <el-option label="全部" :value="undefined"></el-option>-->
                            <el-option
                                v-for="item in crossCitys"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item v-if="form.quoteType==2">
                        <el-select style="width: 200px" placeholder="包车类型" clearable
                                   v-model="form.charteredCarDuration">
                            <!--                            <el-option label="全部" :value="undefined"></el-option>-->
                            <el-option
                                v-for="item in carCharterTypeList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="list-search-after">
                <div style="margin-bottom: 20px" v-if="isHeadquarters">
                    <el-button type="danger" v-if="batchShow" @click="handleBatch">
                        <span v-if="batchShow===1">确定批量生效</span>
                        <span v-if="batchShow===2">确定批量失效</span>
                        <span v-if="batchShow===3">确定批量删除</span>
                    </el-button>
                    <el-button v-if="batchShow===1||!batchShow" type="primary" plain @click="handleBatchShow(1)">
                        {{ batchShow ? '取消批量生效' : '批量生效' }}
                    </el-button>
                    <el-button v-if="batchShow===2||!batchShow" type="primary" plain @click="handleBatchShow(2)">
                        {{ batchShow ? '取消批量失效' : '批量失效' }}
                    </el-button>
                    <el-button v-if="batchShow===3||!batchShow" type="primary" plain @click="handleBatchShow(3)">
                        {{ batchShow ? '取消批量删除' : '批量删除' }}
                    </el-button>
                </div>
                <el-button @click="quoteExport()">导出特定日期加价表</el-button>
                <el-button @click="showQuoteDate()">导入特定日期加价</el-button>
                <el-button @click="resetForm()">重置</el-button>
                <el-button type="primary" @click="search(1)">搜索</el-button>
            </div>
        </com-list-search>
        <el-dialog
            title="导入特定日期加价"
            :visible.sync="dialogVisible"
            width="30%">
            <div>
                <div class="clear">
                    <div class="excel-file-btn">
                        <form ref="file-selector-m">
                            <a href="javascript:;" class="file-btn-a">导入特定日期加价表
                                <input id="uploadm" type="file" @change="handlePreview(this)"
                                       accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>
                            </a>
                        </form>
                    </div>
                    <el-button type="text" style="float: left;margin-left: 20px" @click="downExecl">下载模板</el-button>
                </div>
                <p style="margin-top: 10px">{{ file.name }}</p>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script type="text/ecmascript-6">
import comListSearch from '../../../../../components/comListSearch/index.vue'
import {channelList, quotationTypeList, quotationProductionTypes, crossCitys} from '@/data/index'
import seatModel from "@/components/seatModel/index";
import OperatorInfoInput from '@/www/components/operator-select/'
import SupplierInfoInput from '@/www/components/supplier-select/'
import {exportQuoteRuleList, importQuoteSedDateExcel} from '@/www/urls/quotationUrls'
import {combinationForm} from "../../../../../../common/js/common";
import {initSearchForm} from '@/www/vuex/modules/quotationToolList'
import {uploadExcel, formatExcelDate} from '@/common/js/Utils'
import SelectCity from '@/components/selectCity'
import {status} from '@/data/quotationTool'
import {carCharterTypeList} from '@/data/quotationTool'

export default {
    //定义模版数据
    data() {
        return {
            crossCitys,
            carCharterTypeList,
            form: {
                ...initSearchForm
            },
            supplierUserType: '',
            quotationTypeList: quotationTypeList.map((item) => ({
                    ...item,
                    value: item.value + ''
                })
            ),
            quotationProductionTypes,
            channels: channelList,//渠道
            dialogVisible: false,
            file: {},
            status,
            stationList: []
        }
    },
    components: {
        comListSearch,
        seatModel,
        OperatorInfoInput,
        SupplierInfoInput,
        SelectCity
    },
    //计算属性
    computed: {
        userInfo() {
            return this.$store.state.loginUserInfo;
        },
        batchShow() {
            return this.$store.state.quotationToolList.batchShow
        },
        isHeadquarters() {
            let userInfo = this.$store.state.loginUserInfo;
            let userType = userInfo.userType
            return userType === '0'
        },
        getStationList() {
            return this.stationList.filter(({cityId}) => !this.form.cityId || cityId === this.form.cityId)
        }
    },
    //主件被加载完成
    mounted: function () {
        if (this.userInfo.userType === '0') {
            this.supplierUserType = '2'
        }
        if (this.userInfo.userType === '2') {
            this.form.operatorId = this.userInfo.refId;
        }
        this.getStation();
    },
    //定义事件方法
    methods: {
        selectCity(val = {}) {
            this.form.cityId = val.shortCode;
            this.form.stationCode = null;
        },
        //搜索
        search(pageIndex) {
            this.$store.dispatch('search_quotation_tool_list', {pageIndex, form: this.form})
        },
        //导出
        async quoteExport() {
            this.loadingShow();
            let form = JSON.parse(JSON.stringify(this.form))
            if (form.vehicleType == 0) delete form.vehicleType
            try {
                let res = await exportQuoteRuleList(combinationForm(form));
                let d = new Date().getTime();
                let a = document.createElement('a');
                a.download = '特定日期加价表' + d + '.xls';
                a.href = window.URL.createObjectURL(res);
                a.click();
                this.loadingHide();
            } catch (e) {
                this.loadingHide();
                this.messageError('下载失败')
            }
        },
        //重置
        resetForm() {
            this.handleClear()
            this.form = {...initSearchForm}
            this.$refs['selectCity'].clear();
            this.search(1)
        },
        //导入特定日期加价
        showQuoteDate() {
            this.dialogVisible = true;
            this.file = {};
        },
        handlePreview() {
            // 通过DOM取文件数据
            this.file = event.currentTarget.files[0];
            this.$refs['file-selector-m'].reset();
            this.loadingShow();
            uploadExcel(this.file).then((res) => {
                res = JSON.stringify(res).replace(/规则ID/g, "ruleNo")
                    .replace(/加价开始日期格式（2019-5-4）/g, "startQuoteDay")
                    .replace(/加价开始日期/g, "startQuoteDay")
                    .replace(/加价结束日期格式（2019-5-4）/g, "endQuoteDay")
                    .replace(/加价结束日期/g, "endQuoteDay")
                    .replace(/加价倍数/g, "quotePriceRatio");
                res = JSON.parse(res);
                let data = [];
                res.forEach((item) => {
                    console.log(item);
                    if (!item.ruleNo) {
                        return this.$message.error('规则ID不能为空');
                    }
                    let d = {
                        "ruleNo": item.ruleNo,
                        "startQuoteDay": formatExcelDate(item.startQuoteDay, '/'),
                        "endQuoteDay": formatExcelDate(item.endQuoteDay, '/'),
                        "quotePriceRatio": item.quotePriceRatio,
                    }
                    data.push(d);
                })
                this.upload(data);
            })
        },
        downExecl() {
            window.open('https://cdn.900etrip.com/etrip_bms/excelTemplate/etripDatePrice.xlsx');
        },
        async upload(data) {
            let res = await importQuoteSedDateExcel(data);
            this.loadingHide();
            if (res.success) {
                this.$message.success('上传成功')
            } else {
                this.$message.error(res.errors[0].message);
            }
        },
        //批量删除
        handleBatchShow(index) {
            return this.$store.commit('batch_show', !this.batchShow ? index : false)
        },
        //确定批量删除
        async handleBatch() {
            try {
                const ruleIds = this.$store.state.quotationToolList.list.filter(({checked}) => checked).map(({id}) => id);
                if (ruleIds.length === 0)
                    return this.$message.error('请先选择操作项目')
                let message, action, form;
                switch (this.batchShow) {
                    case 1:
                        action = 'update_quote_status';
                        message = '确定批量生效这些报价公式？';
                        form = {
                            ruleIds,
                            status: '1'
                        }
                        break;
                    case 2:
                        message = '确定批量失效这些报价公式';
                        action = 'update_quote_status';
                        form = {
                            ruleIds,
                            status: '0'
                        }
                        break;
                    case 3:
                        form = ruleIds;
                        action = 'delete_quote_tool';
                        message = '是否批量删除这些报价公式?';
                        break;
                }
                await this.$confirm(message, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                })
                await this.$store.dispatch(action, form);
                this.$store.commit('batch_show', false)
                this.search(this.$store.state.quotationToolList.page.pageIndex)
            } catch (e) {
                console.log(e);
            }
        },
        handleClear() {
            if (this.userInfo.userType === '0') {
                this.supplierUserType = '0'
            }
        },
        async getStation() {
            let form = {
                stationType: 2
            }
            let res = await this.http('/galaxyStationApi/doStationListRemoveDup', form, 'POST');
            if (res.success) {
                this.stationList = res.data;
            }
        },
    },
    watch: {
        'form.operatorId'() {
            if (this.form.operatorId) {
                this.supplierUserType = '2'
            }
            this.form.supplierId = ''
        }
    }
}
</script>

