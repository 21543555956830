<style lang="scss" scoped>
.driver-detail {
    .driver-section-cont {
        .box-card {
            margin-top: 10px;
            padding-bottom: 5px;
        }

        .box-title {
            height: 48px;
            border-bottom: 1px solid #f8f8f8;
            font-weight: 500;
            color: rgba(51, 51, 51, 1);
            line-height: 48px;
            padding-left: 20px;
        }

        .driver-box-card {
            padding: 10px;

            p {
                margin-bottom: 10px;
            }

            .verify-status {
                float: left;
                margin-left: 10px;
                height: 32px;
                width: 179px;
                font-size: 14px;
                font-weight: 400;
                line-height: 32px;
                border-radius: 4px;
                text-align: center;
            }

            .verify-default {
                background: #fff6e7;
                color: rgba(219, 145, 23, 1);
                border: 1px solid #db9117;
            }

            .verify-default1 {
                background: rgba(228, 255, 220, 1);
                color: rgba(32, 146, 139, 1);
                border: 1px solid rgba(32, 146, 139, 1);
            }

            .verify-success {
                background: rgba(240, 248, 255, 1);
                color: rgba(24, 144, 255, 1);
                border: 1px solid rgba(25, 137, 250, 1);
            }

            .verify-refuse {
                background: rgba(255, 239, 238, 1);
                border: 1px solid rgba(255, 59, 48, 1);
                color: rgba(255, 59, 48, 1);
            }

            .verify-btn {
                width: 121px;
                height: 32px;
                margin-right: 20px;
            }
        }

        .label-title {
            border-left: 2px solid #319bf7;
            padding-left: 10px;
            font-weight: 700;
            font-size: 14px;
            margin-left: 10px;
        }
    }

    .table {
        margin-left: 10px;

        tr td {
            width: 190px;
            height: 34px;
            border: 1px solid rgba(220, 223, 230, 1);
            font-weight: 500;
            line-height: 20px;
            font-size: 14px;
            padding-left: 10px;
        }

        .table-title {
            background: rgba(240, 242, 245, 1);
            color: rgba(144, 147, 153, 1);
        }

        .close-button {
            border-radius: 4px;
            border: 1px solid rgba(25, 137, 250, 1);
            color: #319BF7;
        }

        .edit-button {
            width: 121px;
            height: 40px;
        }
    }
}
</style>
<style lang="scss">
.driver-section-cont {
    .el-input.is-disabled {
        .el-input__inner {
            color: #777;
            background-color: #f6f6f6;
        }
    }

    .el-card__body {
        padding: 0;
    }

    .el-icon-circle-close {
        color: #fff;
    }

    .detail-date {
        margin-bottom: 18px;

        .el-form-item--mini.el-form-item, .el-form-item--small.el-form-item {
            margin-bottom: 0;
        }
    }
}

.driver-detail {
    .el-button {
        width: 121px;
    }
}
</style>

<template>
    <div class="driver-detail">
        <el-main class="com-eTrip-section section-has-footer">
            <section-content class="driver-section-cont">
                <el-row>
                    <el-form label-position="right" label-width="120px" size="small" :model="basicForm"
                             :rules="ruleForm" ref="ruleForm">
                        <el-card class="box-card">
                            <p class="box-title">司机基本信息</p>
                            <div class="driver-box-card clear">
                                <p style="margin-top: 5px">
                                    <span class="label-title">状态信息</span>
                                </p>
                                <div class="verify-status"
                                     :class="formatStatus(basicForm.driverAuditStatus).class">
                                    司机基本信息：{{ formatStatus(basicForm.driverAuditStatus).label }}
                                </div>
                                <div style="float:right">
                                    <div v-if="!isEdit">
                                        <template v-if="!basicForm.cancelAccountStatus">
                                            <el-button type="success"
                                                       v-if="!isEditMobile && showEditMobile && basicForm.driverAuditStatus!=1"
                                                       @click="handleEditMobile"
                                                       size="small">编辑
                                            </el-button>

                                            <el-button type="success"
                                                       v-if="basicForm.driverAuditStatus!=2||isCapacityManager||userInfo.refType == 0"
                                                       @click="handleEdit"
                                                       size="small">编辑
                                            </el-button>
                                            <el-button v-if="basicForm.driverAuditStatus==1||basicForm.driverAuditStatus==2||
                                     (basicForm.driverAuditStatus==4&&userInfo.refType!=1)"
                                                       class="verify-btn"
                                                       type="primary"
                                                       size="small"
                                                       @click="verifyDialog(1)">
                                                审核
                                            </el-button>
                                        </template>
                                        <el-button
                                            @click="handleInfoExport"
                                            type="primary"
                                            size="small">台账导出
                                        </el-button>
                                    </div>
                                </div>
                            </div>
                            <div class="driver-box-card clear">
                                <p>
                                    <span class="label-title">身份证</span>
                                </p>
                                <el-col :span="9">
                                    <el-form-item label="姓名：" prop="driverName">
                                        <el-input v-model="basicForm.driverName" :maxLength="50"
                                                  :disabled="!isEdit"></el-input>
                                    </el-form-item>
                                    <el-form-item label="手机号：" prop="driverMobile">
                                        <el-input v-model="basicForm.driverMobile"
                                                  :maxLength="11"
                                                  :disabled="!isEditMobile"></el-input>
                                    </el-form-item>
                                    <el-form-item label="身份证号：" prop="idCardNumber">
                                        <el-input v-model="basicForm.idCardNumber" :maxLength="18" :disabled="!isEdit"
                                                  @change="idCardChange"></el-input>
                                    </el-form-item>
                                    <el-form-item label="性别：" prop="driverGender">
                                        <select-sex v-model="basicForm.driverGender" :disabled="!isEdit"></select-sex>
                                    </el-form-item>
                                    <el-form-item label="民族：" prop="driverNation">
                                        <el-input v-model="basicForm.driverNation" :maxLength="20"
                                                  :disabled="!isEdit"></el-input>
                                    </el-form-item>
                                    <el-form-item label="出生日期：" prop="driverBirthday">
                                        <el-date-picker style="width: 100%"
                                                        v-model="basicForm.driverBirthday" :disabled="!isEdit"
                                                        type="date"
                                                        placeholder="选择日期"
                                                        value-format="yyyy/MM/dd">
                                        </el-date-picker>
                                    </el-form-item>
                                    <el-form-item label="身份证地址：" prop="driverAddress">
                                        <el-input v-model="basicForm.driverAddress" :maxLength="50"
                                                  :disabled="!isEdit"></el-input>
                                    </el-form-item>
                                    <el-form-item label="签发机关：" prop="driverCensus">
                                        <el-input v-model="basicForm.driverCensus" :maxLength="50"
                                                  :disabled="!isEdit"></el-input>
                                    </el-form-item>
                                    <div class="detail-date">
                                        <el-form-item label="有效期：">
                                            <el-col :span="11">
                                                <el-form-item label-width="0" prop="idCardExpiryDateExp">
                                                    <el-date-picker type="date"
                                                                    v-model="basicForm.idCardExpiryDateEff"
                                                                    :disabled="!isEdit" style="width: 100%;"
                                                                    value-format="yyyy/MM/dd">

                                                    </el-date-picker>
                                                </el-form-item>
                                            </el-col>
                                            <el-col class="line" :span="2" style="text-align: center">-</el-col>
                                            <el-col :span="11">
                                                <el-form-item label-width="0" prop="idCardExpiryDateEff">
                                                    <el-date-picker type="date"
                                                                    v-model="basicForm.idCardExpiryDateExp"
                                                                    :disabled="!isEdit" style="width: 100%;"
                                                                    value-format="yyyy/MM/dd"></el-date-picker>
                                                </el-form-item>
                                            </el-col>
                                        </el-form-item>
                                    </div>

                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="身份证正面：">
                                        <div v-if="basicForm.idCardPhotoFace" style="line-height: initial">
                                            <el-image
                                                style="width: 286px;height: 186px;"
                                                :src="basicForm.idCardPhotoFace"
                                                :preview-src-list="[basicForm.idCardPhotoFace]" lazy>
                                            </el-image>
                                            <!--<img :src="basicForm.idCardPhotoFace" style="width: 286px;height: 186px;">-->
                                        </div>
                                        <p v-else>暂无照片</p>
                                        <div style="width: 286px;display: flex;justify-content: center;margin-top: 5px">
                                            <FileUpload v-if="isEdit"
                                                        :title="basicForm.idCardPhotoFace?'重新上传':'上传'"
                                                        :showFileList="false"
                                                        v-model="basicForm.idCardPhotoFace"/>
                                        </div>

                                    </el-form-item>
                                    <el-form-item label="身份证反面：">
                                        <div v-if="basicForm.idCardPhotoBack" style="line-height: initial">
                                            <el-image lazy
                                                      style="width: 286px;height: 186px;"
                                                      :src="basicForm.idCardPhotoBack"
                                                      :preview-src-list="[basicForm.idCardPhotoBack]">
                                            </el-image>
                                            <!--<img :src="basicForm.idCardPhotoBack" style="width: 286px;height: 186px;">-->
                                        </div>
                                        <p v-else>暂无照片</p>
                                        <div style="width: 286px;display: flex;justify-content: center;margin-top: 5px">
                                            <FileUpload v-if="isEdit"
                                                        :title="basicForm.idCardPhotoBack?'重新上传':'上传'"
                                                        :showFileList="false"
                                                        v-model="basicForm.idCardPhotoBack"/>
                                        </div>
                                    </el-form-item>
                                </el-col>
                            </div>
                            <div class="driver-box-card clear">
                                <p>
                                    <span class="label-title">驾驶证</span>
                                </p>
                                <el-col :span="9">
                                    <el-form-item label="营运城市：" prop="cityId">
                                        <el-select v-model="basicForm.cityId" filterable placeholder="填写城市"
                                                   @change="selectCity"
                                                   :disabled="!isEdit" style="width: 100%">
                                            <el-option
                                                v-for="city in serverCityList"
                                                :key="city.shortCode"
                                                :label="city.name"
                                                :value="city.shortCode"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="驾驶证号：" prop="driverLicenseNumber">
                                        <el-input v-model="basicForm.driverLicenseNumber" :disabled="!isEdit"
                                                  @change="idCardChange"
                                                  :maxLength="18"></el-input>
                                    </el-form-item>
                                    <el-form-item label="国籍：" prop="driverNationality">
                                        <el-input v-model="basicForm.driverNationality" :disabled="!isEdit"></el-input>
                                    </el-form-item>
                                    <el-form-item label="准驾车型：" prop="driverType">
                                        <el-select v-model="basicForm.driverType" :disabled="!isEdit"
                                                   style="width: 100%">
                                            <el-option
                                                v-for="item in driverTypeList"
                                                :key="item"
                                                :label="item"
                                                :value="item"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="驾驶证地址：" prop="driverLicenseAddress">
                                        <el-input v-model="basicForm.driverLicenseAddress"
                                                  :disabled="!isEdit"></el-input>
                                    </el-form-item>
                                    <el-form-item label="初次领证日期：" prop="getDriverLicenseDate">
                                        <el-date-picker style="width: 100%"
                                                        v-model="basicForm.getDriverLicenseDate" :disabled="!isEdit"
                                                        type="date"
                                                        placeholder="选择日期"
                                                        value-format="yyyy/MM/dd">
                                        </el-date-picker>
                                    </el-form-item>
                                    <div class="detail-date">
                                        <el-form-item label="有效日期：">

                                            <el-col :span="11">
                                                <el-form-item prop="driverLicenseOn">
                                                    <el-date-picker type="date" v-model="basicForm.driverLicenseOn"
                                                                    :disabled="!isEdit" style="width: 100%;"
                                                                    value-format="yyyy/MM/dd"></el-date-picker>
                                                </el-form-item>
                                            </el-col>
                                            <el-col class="line" :span="2" style="text-align: center">-</el-col>
                                            <el-col :span="11">
                                                <el-form-item prop="driverLicenseOff">
                                                    <el-date-picker type="date" v-model="basicForm.driverLicenseOff"
                                                                    :disabled="!isEdit" style="width: 100%;"
                                                                    value-format="yyyy/MM/dd"></el-date-picker>
                                                </el-form-item>
                                            </el-col>
                                        </el-form-item>
                                    </div>

                                    <el-form-item label="档案编号：">
                                        <el-input v-model="basicForm.driverLicenseFileNo"
                                                  :disabled="!isEdit"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="驾驶证正面：">
                                        <div v-if="basicForm.driverLicensePhoto" style="line-height: initial">
                                            <el-image lazy
                                                      style="width: 286px;height: 186px;"
                                                      :src="basicForm.driverLicensePhoto"
                                                      :preview-src-list="[basicForm.driverLicensePhoto]">
                                            </el-image>
                                            <!--<img :src="basicForm.driverLicensePhoto"-->
                                            <!--style="width: 276px;height: 180px;">-->
                                        </div>
                                        <p v-else>暂无照片</p>
                                        <div style="width: 286px;display: flex;justify-content: center;margin-top: 5px">
                                            <FileUpload v-if="isEdit"
                                                        :title="basicForm.driverLicensePhoto?'重新上传':'上传'"
                                                        :showFileList="false"
                                                        v-model="basicForm.driverLicensePhoto"/>
                                        </div>
                                    </el-form-item>
                                </el-col>
                            </div>
                            <div class="driver-box-card clear">
                                <p>
                                    <span class="label-title">合同记录</span>
                                </p>
                                <table class="table">
                                    <tr class="table-title">
                                        <td>合同编号</td>
                                        <td>合同签署公司</td>
                                        <td>合同有效期限</td>
                                        <td>合同状态</td>
                                        <td>合同附件</td>
                                    </tr>
                                    <tr v-for="item in (basicForm.driverContractDtos||[])" :key="item.id">
                                        <td>{{ item.contractNo }}</td>
                                        <td>{{ item.contractCompany }}</td>
                                        <td>{{ item.contractOn + "-" + item.contractOff }}</td>
                                        <td>{{ item.contractStatus }}</td>
                                        <td>
                                            <el-button type="text" @click="look(item.contractFile)"
                                                       v-if="item.contractFile">查看
                                            </el-button>
                                            <span v-else>无</span>
                                        </td>
                                    </tr>
                                    <tr v-if="!basicForm.driverContractDtos||basicForm.driverContractDtos.length===0">
                                        <td colspan="5">
                                            无
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div class="driver-box-card clear">
                                <p>
                                    <span class="label-title">培训记录</span>
                                </p>
                                <table class="table">
                                    <tr class="table-title">
                                        <td>培训课程名称</td>
                                        <td>培训课程日期</td>
                                        <td>培训开始时间</td>
                                        <td>培训结束时间</td>
                                        <td>培训时长</td>
                                    </tr>
                                    <tr v-for="item in (basicForm.driverCourseDtos||[])" :key="item.id">
                                        <td>{{ item.courseName }}</td>
                                        <td>{{ item.courseDate }}</td>
                                        <td>{{ item.courseStartTime }}</td>
                                        <td>{{ item.courseStopTime }}</td>
                                        <td>{{ item.duration }}</td>
                                    </tr>
                                    <tr v-if="!basicForm.driverCourseDtos||basicForm.driverCourseDtos.length===0">
                                        <td colspan="5">
                                            无
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div class="driver-box-card clear">
                                <p>
                                    <span class="label-title">绑定车辆</span>
                                </p>
                                <table class="table">
                                    <tr class="table-title">
                                        <td>车牌号</td>
                                        <td>品牌型号</td>
                                        <td>车辆类型</td>
                                        <td>车身颜色</td>
                                    </tr>
                                    <tr v-if="basicForm.carNumberPlate">
                                        <td>{{ basicForm.carNumberPlate }}</td>
                                        <td>{{ basicForm.carBrand + " " + basicForm.carModel }}</td>
                                        <td>{{ formatCarModel(basicForm.carType).name }}</td>
                                        <td>{{ basicForm.carColor }}</td>
                                    </tr>
                                    <tr v-else>
                                        <td colspan="4">
                                            无
                                        </td>
                                    </tr>
                                </table>
                                <div style="padding: 10px;display: flex;align-items: center">
                                    是否带车：
                                    <el-radio-group v-model="basicForm.personalCar" :disabled="!isEdit">
                                        <el-radio :label="0">否</el-radio>
                                        <el-radio :label="1">是</el-radio>
                                    </el-radio-group>
                                </div>
                            </div>
                        </el-card>
                    </el-form>
                    <el-form label-position="right" label-width="120px" size="small" :model="netForm"
                             :rules="ruleForm" ref="netRule">
                        <el-card class="box-card"
                                 v-if="netForm.driverCertificateAuditStatus !=0&&netForm.driverCertificateAuditStatus !=5">
                            <p class="box-title">司机网约车信息</p>
                            <div class="driver-box-card clear">
                                <p style="margin-top: 5px">
                                    <span class="label-title">状态信息</span>
                                </p>
                                <div class="verify-status"
                                     :class="formatStatus(netForm.driverCertificateAuditStatus).class">
                                    车辆网约信息：{{ formatStatus(netForm.driverCertificateAuditStatus).label }}
                                </div>
                                <div class="verify-status"
                                     :class="{'verify-default': netForm.driverFlagStatus ==0,
                                     'verify-success': netForm.driverFlagStatus ==1,
                                     'verify-refuse': netForm.driverFlagStatus ==2}">
                                    司机备案状态：{{
                                        netForm.driverFlagStatus == 0 ? "未备案" : (netForm.driverFlagStatus
                                        == 1 ? "已备案" : "备案失败")
                                    }}
                                </div>
                                <div style="float:right" v-if="!isNetFormEdit&&!basicForm.cancelAccountStatus">
                                    <el-button type="success"
                                               size="small"
                                               v-if="netForm.driverCertificateAuditStatus!=2||isCapacityManager"
                                               @click="isNetFormEdit=true">编辑
                                    </el-button>
                                    <el-button class="verify-btn" type="primary" size="small" @click="verifyDialog(2)"
                                               v-if="netForm.driverCertificateAuditStatus==1||netForm.driverCertificateAuditStatus==2||(netForm.driverCertificateAuditStatus==4&&userInfo.refType!=1)">
                                        审核
                                    </el-button>
                                    <el-button class="verify-btn"
                                               type="primary"
                                               size="small"
                                               :disabled="netForm.driverCertificateAuditStatus!=2||netForm.driverAuditStatus!=2"
                                               @click="flagVerify()">备案
                                    </el-button>
                                </div>
                            </div>
                            <div class="driver-box-card clear">
                                <p>
                                    <span class="label-title">网络预约出租车汽车经营者资格证</span>
                                </p>
                                <el-col :span="9">
                                    <el-form-item label="资格证号：" prop="certificateNo">
                                        <el-input v-model="netForm.certificateNo" :disabled="!isNetFormEdit"
                                                  :maxLength="18">
                                            <driver-vehicle-verification slot="suffix" :type="0" :info="netForm"/>
                                        </el-input>
                                    </el-form-item>
                                    <el-form-item label="发证机构：" prop="networkCarIssueOrganization">
                                        <el-input v-model="netForm.networkCarIssueOrganization"
                                                  :disabled="!isNetFormEdit"></el-input>
                                    </el-form-item>
                                    <el-form-item label="初次领证日期：" prop="getNetworkCarProofDate">
                                        <el-date-picker type="date" v-model="netForm.getNetworkCarProofDate"
                                                        :disabled="!isNetFormEdit" style="width: 100%"
                                                        value-format="yyyy/MM/dd"></el-date-picker>
                                    </el-form-item>
                                    <div class="detail-date">
                                        <el-form-item label="有效期限：">
                                            <el-col :span="11">
                                                <el-form-item prop="networkCarProofOn">
                                                    <el-date-picker type="date"
                                                                    v-model="netForm.networkCarProofOn"
                                                                    :disabled="!isNetFormEdit"
                                                                    value-format="yyyy/MM/dd"
                                                                    style="width: 100%;">
                                                    </el-date-picker>
                                                </el-form-item>
                                            </el-col>
                                            <el-col class="line" :span="2" style="text-align: center">-</el-col>
                                            <el-col :span="11">
                                                <el-form-item prop="networkCarProofOff">
                                                    <el-date-picker type="date" v-model="netForm.networkCarProofOff"
                                                                    :disabled="!isNetFormEdit"
                                                                    value-format="yyyy/MM/dd"
                                                                    style="width: 100%;"></el-date-picker>
                                                </el-form-item>
                                            </el-col>
                                        </el-form-item>
                                    </div>

                                    <el-form-item label="报备日期：" prop="registerDate">
                                        <el-date-picker type="date" v-model="netForm.registerDate"
                                                        :disabled="!isNetFormEdit"
                                                        value-format="yyyy/MM/dd" style="width: 100%"></el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="资格证照片：" label-width="180px">
                                        <div v-if="netForm.certificatePhoto">
                                            <el-image lazy
                                                      style="width: 286px;height: 186px;"
                                                      :src="netForm.certificatePhoto"
                                                      :preview-src-list="[netForm.certificatePhoto]">
                                            </el-image>
                                        </div>
                                        <p v-else>暂无照片</p>
                                        <FileUpload v-if="isNetFormEdit"
                                                    :title="netForm.certificatePhoto?'重新上传':'上传'"
                                                    :showFileList="false"
                                                    v-model="netForm.certificatePhoto"/>
                                    </el-form-item>
                                </el-col>
                            </div>
                            <div class="driver-box-card clear">
                                <p>
                                    <span class="label-title">其他信息</span>
                                </p>
                                <el-col :span="9">
                                    <el-form-item label="手机型号：" prop="mobileModel">
                                        <el-input v-model="netForm.mobileModel" :disabled="!isNetFormEdit"></el-input>
                                    </el-form-item>
                                    <el-form-item label="手机运营商：" prop="netType">
                                        <el-select v-model="netForm.netType" :disabled="!isNetFormEdit"
                                                   style="width: 100%">
                                            <el-option label="中国联通" value="1"></el-option>
                                            <el-option label="中国移动" value="2"></el-option>
                                            <el-option label="中国电信" value="3"></el-option>
                                            <el-option label="其他" value="4"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="APP版本：" prop="appVersion">
                                        <el-input v-model="netForm.appVersion" :disabled="!isNetFormEdit"
                                                  style="width: 100%"></el-input>
                                    </el-form-item>
                                    <el-form-item label="使用地图类型：" prop="mapType">
                                        <el-select v-model="netForm.mapType" :disabled="!isNetFormEdit"
                                                   style="width: 100%">
                                            <el-option label="百度地图" :value="1"></el-option>
                                            <el-option label="高德地图" :value="2"></el-option>
                                            <el-option label="其他" :value="3"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="婚姻状况：" prop="driverMaritalStatus">
                                        <el-select v-model="netForm.driverMaritalStatus" :disabled="!isNetFormEdit"
                                                   style="width: 100%">
                                            <el-option label="未知" value="未知"></el-option>
                                            <el-option value="未婚"></el-option>
                                            <el-option value="已婚"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="学历：" prop="driverEducation">
                                        <el-select v-model="netForm.driverEducation" :disabled="!isNetFormEdit"
                                                   style="width: 100%">
                                            <el-option
                                                v-for="e in educationList"
                                                :key="e"
                                                :label="e"
                                                :value="e"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="外语能力：" prop="driverLanguageLevel">
                                        <el-input v-model="netForm.driverLanguageLevel"
                                                  :disabled="!isNetFormEdit"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="9">
                                    <el-form-item label="驾驶员服务类型：" label-width="180px"
                                                  prop="driverCommercialType">
                                        <el-select v-model="netForm.driverCommercialType" :disabled="!isNetFormEdit"
                                                   style="width: 100%">
                                            <el-option label="网络预约出租汽车" :value="1"></el-option>
                                            <el-option label="巡游出租汽车" :value="2"></el-option>
                                            <el-option label="私人小客车合乘" :value="3"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="是否巡游出租车驾驶员：" label-width="180px" prop="taxiDriver">
                                        <el-select v-model="netForm.taxiDriver" :disabled="!isNetFormEdit"
                                                   style="width: 100%">
                                            <el-option label="否" :value="0"></el-option>
                                            <el-option label="是" :value="1"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="是否专职驾驶员：" label-width="180px" prop="fullTimeDriver">
                                        <el-select v-model="netForm.fullTimeDriver" :disabled="!isNetFormEdit"
                                                   style="width: 100%">
                                            <el-option label="否" :value="0"></el-option>
                                            <el-option label="是" :value="1"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="是否在黑名单内：" label-width="180px" prop="inDriverBlackList">
                                        <el-select v-model="netForm.inDriverBlackList" :disabled="!isNetFormEdit"
                                                   style="width: 100%">
                                            <el-option label="否" :value="0"></el-option>
                                            <el-option label="是" :value="1"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="紧急联系人：" label-width="180px" prop="emergencyContact">
                                        <el-input v-model="netForm.emergencyContact"
                                                  :disabled="!isNetFormEdit"></el-input>
                                    </el-form-item>
                                    <el-form-item label="紧急联系人电话：" label-width="180px"
                                                  prop="emergencyContactPhone">
                                        <el-input v-model="netForm.emergencyContactPhone" :maxlength="11"
                                                  :disabled="!isNetFormEdit"></el-input>
                                    </el-form-item>
                                    <el-form-item label="紧急联系人地址：" label-width="180px"
                                                  prop="emergencyContactAddress">
                                        <el-input v-model="netForm.emergencyContactAddress"
                                                  :disabled="!isNetFormEdit"></el-input>
                                    </el-form-item>
                                </el-col>
                            </div>
                        </el-card>
                    </el-form>
                </el-row>
            </section-content>
        </el-main>
        <el-footer class="com-eTrip-footer" height="64px">
            <div class="eTrip-footer-cont">
                <div style="text-align: right">
                    <el-row>
                        <div style="float: right;" v-if="isEdit||isNetFormEdit||isEditMobile">
                            <span style="font-size: 14px;padding-right: 30px">正在编辑</span>
                            <el-button class="close-button edit-button" size="small" @click="close()">取消</el-button>
                            <el-button type="primary" class="edit-button" size="small" @click="save()">保存</el-button>
                        </div>
                        <!--<div style="line-height: 40px;float: right;margin-right: 20px" v-else>-->
                        <!--<el-button type="primary"-->
                        <!--class="edit-button"-->
                        <!--size="small"-->
                        <!--v-if=""-->
                        <!--@click="isEdit=true">编辑模式-->
                        <!--</el-button>-->
                        <!--&lt;!&ndash;<el-radio-group v-model="isEdit" @change>&ndash;&gt;-->
                        <!--&lt;!&ndash;<el-radio :label="false">查看模式</el-radio>&ndash;&gt;-->
                        <!--&lt;!&ndash;<el-radio :label="true">编辑模式</el-radio>&ndash;&gt;-->
                        <!--&lt;!&ndash;</el-radio-group>&ndash;&gt;-->
                        <!--</div>-->
                    </el-row>
                </div>
            </div>
        </el-footer>
        <el-dialog
            :visible.sync="dialogVisible"
            width="30%"
            :before-close="handleClose">
            <div slot="title">
                <div v-if="!isRefuse">
                    <i class="el-icon-warning" size="22" style="color: #FAAD14"></i>
                    <span style="padding-left: 10px;font-weight: 700;font-size: 16px">确定通过审核吗？</span>
                </div>
                <div v-else>
                    <span style="font-weight: 700;font-size: 16px">请输入拒绝通过的原因：</span>
                </div>
            </div>
            <div v-if="!isRefuse">
                <p>此操作不可逆，请确认内容已被仔细审阅，没有遗漏。</p>
            </div>
            <div v-else>
                <el-form :model="refuseForm" ref="refuseForm" :rules="refuseRule">
                    <el-form-item prop="remarks">
                        <el-input v-model="refuseForm.remarks" type="textarea" :rows="3"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer" v-if="!isRefuse">
                <el-button @click="isRefuse = true">不通过</el-button>
                <el-button type="primary" @click="successVerify()">通过</el-button>
            </div>
            <div v-else slot="footer" class="dialog-footer">
                <el-button @click="isRefuse = false">取消</el-button>
                <el-button type="primary" @click="failVerify()">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script type="text/ecmascript-6">
import {getCityData} from "@/urls/index";
import {getSeatModel} from "@/data/index";
import SelectSex from "@/components/selectSex/index";
import FileUpload from "@/components/fileUpload";
// import infoPrint from "../components/infoPrint"
import {
    validateName,
    validatePhone,
    validateId,
    validateChinese,
    validateChart,
    getStatusInfo
} from "../service"
import DriverVehicleVerification from "@/www/pages/driverManage/components/driver-vehicle-verification.vue";

export default {
    name: 'driverDetail',
    //定义模版数据
    data() {
        return {
            basicForm: {
                mapType: 1,
                driverMaritalStatus: "未知",
                driverEducation: "未知",
                driverLanguageLevel: "未知",
                driverCommercialType: 1,
                taxiDriver: 0,
                fullTimeDriver: 1,
                inDriverBlackList: 0
            },
            netForm: {
                mapType: 1,
                driverMaritalStatus: "未知",
                driverEducation: "未知",
                driverLanguageLevel: "未知",
                driverCommercialType: 1,
                taxiDriver: 0,
                fullTimeDriver: 1,
                inDriverBlackList: 0
            },
            resetForm: {},
            serverCityList: [],
            brandModelList: [],
            isEdit: false,
            isEditMobile: false,
            isNetFormEdit: false,
            educationList: [
                "小学", "初中", "高中", "专科", "本科", "硕士", "博士", "未知"
            ],
            driverTypeList: ["A1", "A2", "A3", "B1", "B2", "B1B2", "C1", "C2", "C1M"],
            ruleForm: {
                driverName: [{validator: validateName, trigger: 'blur'}],
                driverMobile: [{validator: validatePhone, trigger: 'blur'}],
                idCardNumber: [{validator: validateId, trigger: 'blur'}],
                driverGender: [{required: true, message: '请选择性别', trigger: 'change'}],
                driverNation: [{validator: validateChinese, trigger: 'blur'}],
                driverBirthday: [{required: true, message: '请选择日期', trigger: 'change'}],
                driverAddress: [{required: true, trigger: 'blur'}],
                driverCensus: [{validator: validateChart, trigger: 'blur'}],
                idCardExpiryDateExp: [{required: true, message: '请选择日期', trigger: 'change'}],
                idCardExpiryDateEff: [{required: true, message: '请选择日期', trigger: 'change'}],
                driverLicenseNumber: [{validator: validateId, trigger: 'blur'}],
                driverNationality: [{validator: validateChinese, trigger: 'blur'}],
                driverType: [{required: true, message: '请选择准驾车型', trigger: 'change'}],
                driverLicenseAddress: [{required: true, trigger: 'blur'}],
                getDriverLicenseDate: [{required: true, message: '请选择日期', trigger: 'change'}],
                driverLicenseOn: [{required: true, message: '请选择日期', trigger: 'change'}],
                driverLicenseOff: [{required: true, message: '请选择日期', trigger: 'change'}],
                certificateNo: [{required: true, message: '请填写资格证号', trigger: 'blur'}],
                networkCarIssueOrganization: [{validator: validateChart, trigger: 'blur'}],
                getNetworkCarProofDate: [{required: true, message: '请选择日期', trigger: 'change'}],
                networkCarProofOn: [{required: true, message: '请选择日期', trigger: 'change'}],
                networkCarProofOff: [{required: true, message: '请选择日期', trigger: 'change'}],
                registerDate: [{required: true, message: '请选择日期', trigger: 'change'}],
                mobileModel: [{required: true, message: '请输入手机型号', trigger: 'blur'}],
                netType: [{required: true, message: '请选择手机运营商', trigger: 'change'}],
                appVersion: [{required: true, message: '请输入内容', trigger: 'blur'}],
                mapType: [{required: true, message: '请选择地图类型', trigger: 'change'}],
                driverMaritalStatus: [{required: true, message: '请选择婚姻状态', trigger: 'change'}],
                driverEducation: [{required: true, message: '请选择学历', trigger: 'change'}],
                driverLanguageLevel: [{validator: validateChart, trigger: 'blur'}],
                driverCommercialType: [{required: true, message: '请选择服务类型', trigger: 'change'}],
                emergencyContact: [{validator: validateName, trigger: 'blur'}],
                emergencyContactPhone: [{validator: validatePhone, trigger: 'blur'}],
                emergencyContactAddress: [{required: true, trigger: 'blur'}],
            },
            dialogVisible: false,
            dialogIndex: 0,
            isRefuse: false,
            refuseForm: {
                remarks: null
            },
            refuseRule: {
                remarks: [{required: true, message: '请输入拒绝理由', trigger: 'blur'}]
            }

        };
    },
    //计算属性
    computed: {
        userInfo() {
            return this.$store.state.loginUserInfo;
        },
        isCapacityManager() {
            return ['总部运力管理员', '总部管理员', '总部超级管理员'].includes(this.$store.state.loginUserInfo.roleName)
        },
        showEditMobile() {
            return Number(this.$store.state.loginUserInfo.refType) === 0 ? false : true
        }
    },
    components: {
        DriverVehicleVerification,
        SelectSex,
        FileUpload
        // infoPrint
    },
    //主件被加载完成
    mounted: function () {
        this.getServerCity();
    },
    activated() {
        let id = this.$route.params.id;
        if (this._driverId === id)
            return;
        this._driverId = id;
        this.isEdit = false;
        this.isEditMobile = false
        this.getDetail();
    },
    //定义事件方法
    methods: {
        handleEdit() {
            this.isEdit = true
            this.isEditMobile = true
        },
        handleEditMobile() {
            this.isEditMobile = true
        },
        async getServerCity() {
            const ret = await getCityData(this, "2");
            if (ret) this.serverCityList = ret;
        },
        selectCity(cityId) {
            const city = this.serverCityList.find(item => {
                return (item.shortCode == cityId)
            })
            this.basicForm.cityName = city.name;
        },
        async getDetail() {
            let res = await this.http(
                `/galaxyDriverApproveApi/doDriverInfoDetails/${this._driverId}`
            );
            if (res.success) {
                const data = JSON.parse(JSON.stringify(res.data));
                this.basicForm = data;
                this.netForm = data;
                this.resetForm = data;
                this.$refs.ruleForm.clearValidate();
                this.$refs.netRule.clearValidate();
            }
        },
        formatCarModel(type) {
            let info = {};
            if (type) {
                info = getSeatModel(parseInt(type));
                // console.log(info, type);
            }
            return info;
        },
        save() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    if (this.showEditMobile && this.basicForm.driverAuditStatus != 1) {
                        let {driverMobile} = this.basicForm
                        this.$confirm(`司机手机号修改为${driverMobile},若此司机有待出行订单请重新分配,以防影响乘客联系`, '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {
                            this.submit();
                        }).catch(() => {
                        });
                    } else {
                        this.submit();
                    }
                } else {
                    this.messageError("信息不全,请填写完整")
                }
            })
        },
        async submit() {
            let form = {
                cityId: this.basicForm.cityId,
                cityName: this.basicForm.cityName,
                driverAddress: this.basicForm.driverAddress,
                driverAuditStatus: this.basicForm.driverAuditStatus,
                driverBirthday: this.basicForm.driverBirthday,
                driverCensus: this.basicForm.driverCensus,
                driverGender: this.basicForm.driverGender,
                driverLicenseAddress: this.basicForm.driverLicenseAddress,
                driverLicenseFileNo: this.basicForm.driverLicenseFileNo,
                driverLicenseNumber: this.basicForm.driverLicenseNumber,
                driverLicenseOff: this.basicForm.driverLicenseOff,
                driverLicenseOn: this.basicForm.driverLicenseOn,
                driverLicensePhoto: this.basicForm.driverLicensePhoto,
                driverMobile: this.basicForm.driverMobile,
                driverName: this.basicForm.driverName,
                driverNation: this.basicForm.driverNation,
                driverNationality: this.basicForm.driverNationality,
                driverType: this.basicForm.driverType,
                getDriverLicenseDate: this.basicForm.getDriverLicenseDate,
                id: this.basicForm.id,
                idCardExpiryDateEff: this.basicForm.idCardExpiryDateEff,
                idCardExpiryDateExp: this.basicForm.idCardExpiryDateExp,
                idCardNumber: this.basicForm.idCardNumber,
                idCardPhotoBack: this.basicForm.idCardPhotoBack,
                idCardPhotoFace: this.basicForm.idCardPhotoFace,
                licenseId: this.basicForm.licenseId,
                loginUserId: this.basicForm.loginUserId,
                supplierId: this.basicForm.supplierId,
                supplierName: this.basicForm.supplierName,
                personalCar: this.basicForm.personalCar
            };
            form.driverInfoNetCarUpdateReqDto = {
                appVersion: this.netForm.appVersion,
                certificateNo: this.netForm.certificateNo,
                certificatePhoto: this.netForm.certificatePhoto,
                driverCertificateAuditStatus: this.netForm.driverCertificateAuditStatus,
                driverCommercialType: this.netForm.driverCommercialType,
                driverContractDto: this.basicForm.driverContractDtos,
                driverCourseDto: this.basicForm.driverCourseDtos,
                driverEducation: this.netForm.driverEducation,
                driverLanguageLevel: this.netForm.driverLanguageLevel,
                driverMaritalStatus: this.netForm.driverMaritalStatus,
                emergencyContact: this.netForm.emergencyContact,
                emergencyContactAddress: this.netForm.emergencyContactAddress,
                emergencyContactPhone: this.netForm.emergencyContactPhone,
                fullTimeDriver: this.netForm.fullTimeDriver,
                getNetworkCarProofDate: this.netForm.getNetworkCarProofDate,
                id: this.netForm.netCarId,
                inDriverBlackList: this.netForm.inDriverBlackList,
                mapType: this.netForm.mapType,
                mobileModel: this.netForm.mobileModel,
                netType: this.netForm.netType,
                networkCarIssueOrganization: this.netForm.networkCarIssueOrganization,
                networkCarProofOff: this.netForm.networkCarProofOff,
                networkCarProofOn: this.netForm.networkCarProofOn,
                registerDate: this.netForm.registerDate,
                taxiDriver: this.netForm.taxiDriver
            };
            this.loadingShow();
            let res = await this.http("/galaxyDriverApproveApi/doDriverInfoApprovalUpdate", form, "POST");
            this.loadingHide();
            if (res.success) {
                this.isEdit = false;
                this.isEditMobile = false;
                this.isNetFormEdit = false;
                this.getDetail();
                this.$message.success("修改成功")
            }
        },
        close() {
            this.$confirm('是否取消编辑?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                const resetForm = JSON.parse(JSON.stringify(this.resetForm));
                this.basicForm = resetForm;
                this.netForm = resetForm;
                this.isEdit = false;
                this.isEditMobile = false;
                this.isNetFormEdit = false;
            }).catch(() => {
            });
        },
        idCardChange(val) {
            this.basicForm.idCardNumber = val;
            this.basicForm.driverLicenseNumber = val;
            this.netForm.certificateNo = val;
        },
        formatStatus(status) {

            let info = getStatusInfo(status || 0);
            return info;
        },
        async verify(status) {
            let form = {
                loginUserId: this.basicForm.loginUserId,
                id: this.basicForm.id,
                driverAuditStatus: status
            }
            if (status == 3) form.remarks = this.refuseForm.remarks;
            this.loadingShow();
            let res = await this.http("/galaxyDriverApproveApi/doDriverInfoApproval", form, "POST");
            this.loadingHide();
            this.dialogVisible = false;
            this.isRefuse = false;
            if (res.success) {
                this.$message.success("操作成功")
                this.getDetail()
            } else {
                this.$message.success("操作失败")
            }
        },
        async flagVerify() {
            this.$refs.netRule.validate(async (valid) => {
                if (valid) {
                    this.$confirm(this.netForm.driverFlagStatus === 1 ? '该司机已备案成功，是否重新备案？' : '是否备案?', '提示', {
                        confirmButtonText: this.netForm.driverFlagStatus === 1 ? '重新备案' : '备案',
                        cancelButtonText: '取消'
                    }).then(async () => {
                        let form = {
                            loginUserId: this.basicForm.loginUserId,
                            driverMobile: this.basicForm.driverMobile,
                            driverFlagStatus: 1

                        }
                        this.loadingShow();
                        let res = await this.http("/galaxyDriverApproveApi/doDriverInfoNetCarRecord", form, "POST");
                        console.log(res);
                        if (res.success) {
                            this.refuseForm.remarks = null;
                            this.$message.success("操作成功")
                            await this.getDetail()
                        } else {
                            this.$message.error("操作失败")
                        }
                        this.loadingHide();
                    }).catch(() => {
                    });
                } else {
                    this.messageError("信息不全,请填写完整")
                }
            })
        },
        async netVerify(status) {
            let form = {
                loginUserId: this.basicForm.loginUserId,
                id: this.basicForm.netCarId,
                driverCertificateAuditStatus: status

            }
            if (status == 3) form.remarks = this.refuseForm.remarks;
            this.loadingShow();
            let res = await this.http("/galaxyDriverApproveApi/doDriverNetCarApproval", form, "POST");
            this.loadingHide();
            this.dialogVisible = false;
            this.isRefuse = false;
            if (res.success) {
                this.refuseForm.remarks = null;
                this.$message.success("操作成功")
                this.getDetail()
            } else {
                this.$message.error("操作失败")
            }
        },
        handleInfoExport() {
            this.$router.push(`/infoPrint/0?ids=${this._driverId}`);

        },
        verifyDialog(index) {
            this.dialogVisible = true;
            this.dialogIndex = index;
        },
        handleClose(done) {
            done();
        },
        successVerify() {
            let name = this.dialogIndex === 2 ? "netRule" : "ruleForm";
            this.$refs[name].validate(async (valid) => {
                if (valid) {
                    let status = 4;
                    if (this.dialogIndex == 1) {
                        if (this.basicForm.driverAuditStatus == 4 || this.userInfo.refType != 1) status = 2;
                        this.verify(status);
                    }
                    if (this.dialogIndex == 2) {
                        if (this.basicForm.driverCertificateAuditStatus == 4 || this.userInfo.refType != 1) status = 2;
                        this.netVerify(status);
                    }

                } else {
                    this.messageError("信息不全,请填写完整")
                    this.dialogVisible = false;
                }
            })

        },
        failVerify() {
            this.$refs.refuseForm.validate(async (valid) => {
                if (valid) {
                    if (this.dialogIndex == 1) this.verify(3);
                    if (this.dialogIndex == 2) this.netVerify(3);
                }

            })
        },
        look(url) {
            window.open(url)
        }
    },
    //监听模版变量
    watch: {}
};
</script>
